import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    {
        name: 'index',
        path: '/',
        component: () => import('@/views/index.vue')
    },
    {
        name: 'career',
        path: '/career/:id',
        component: () => import('@/views/career/index.vue')
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: () => import('@/views/privacy')
    },
    {
      name: 'terms',
      path: '/terms',
      component: () => import('@/views/privacy/terms')
    },
    {
      name: 'deletion',
      path: '/deletion',
      component: () => import('@/views/privacy/deletion')
    }
]


let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes:constantRoutes
})
// 防止连续点击多次路由报错
